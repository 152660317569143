$(function() {
    function scrollTo(offset, duration) {
        offset = offset || 0;
        duration = duration || 1000;
        $('html,body').stop().animate({
            scrollTop: offset
        }, duration);
    }

    const
        $forGuestsSlider = $('#for-guests-slider'),
        $dateIn = $('[data-role="date-in"]'),
        $dateOut = $('[data-role="date-out"]'),
        $dateIn2 = $('[data-role="date-in-2"]'),
        $dateOut2 = $('[data-role="date-out-2"]'),
        now = new Date().getTime() + 86400000;

    if ($forGuestsSlider.length) {
        $forGuestsSlider.slick({
            infinite: false,
            arrows:   false,
            dots:     true
        });
    }
    if ($dateIn.length) {
        var inDate = new Date(),
            inPicker = $dateIn.datepicker({
                altField: '#date-in',
                altFieldDateFormat: 'mm/dd/yy',
                minDate:    new Date(),
                onSelect:   function(fd, d) {
                    inDate = d;
                    if (inDate >= outDate) {
                        outDate.setTime(inDate.getTime() + 86400000);
                        outPicker.selectDate(outDate);
                    }
                },
                dateFormat: 'dd/mm/yy'
            }).data('datepicker');
        inPicker.selectDate(inDate);
    }
    if ($dateOut.length) {
        var outDate = new Date(now),
            outPicker = $dateOut.datepicker({
                altField: '#date-out',
                altFieldDateFormat: 'mm/dd/yy',
                minDate:    new Date(now),
                onSelect:   function(fd, d) {
                    outDate = d;
                    if (outDate <= inDate) {
                        inDate.setTime(outDate.getTime() - 86400000);
                        inPicker.selectDate(inDate);
                    }
                },
                dateFormat: 'dd/mm/yy'
            }).data('datepicker');
        outPicker.selectDate(outDate);
    }
    if ($dateIn2.length) {
        var inDate2 = new Date(),
            inPicker2 = $dateIn2.datepicker({
                altField: '#date-in-2',
                altFieldDateFormat: 'mm/dd/yy',
                minDate:    new Date(),
                onSelect:   function(fd, d) {
                    inDate2 = d;
                    if (inDate2 >= outDate2) {
                        outDate2.setTime(inDate2.getTime() + 86400000);
                        outPicker2.selectDate(outDate2);
                    }
                },
                dateFormat: 'dd/mm/yy'
            }).data('datepicker');
        inPicker2.selectDate(inDate2);
    }
    if ($dateOut2.length) {
        var outDate2 = new Date(now),
            outPicker2 = $dateOut2.datepicker({
                altField: '#date-out-2',
                altFieldDateFormat: 'mm/dd/yy',
                minDate:    new Date(now),
                onSelect:   function(fd, d) {
                    outDate2 = d;
                    if (outDate2 <= inDate2) {
                        inDate2.setTime(outDate2.getTime() - 86400000);
                        inPicker2.selectDate(inDate2);
                    }
                },
                dateFormat: 'dd/mm/yy'
            }).data('datepicker');
        outPicker2.selectDate(outDate2);
    }
    $('a')
        .on('click', function() {
            var id = $(this).attr('href') || '#',
                offset = 0,
                $mMenu = $('#mainMenu');

            if (id.search(/#[A-Za-z0-9_.:\-]+/g) === 0) {
                if ($(id).length) offset = $(id).offset().top - 64;

                if ($mMenu.length) {
                    $('[data-role="toggleMenu"]').removeClass('active');
                    $('.active', $mMenu).removeClass('active');
                    $mMenu.removeClass('active');
                    $(this, $mMenu).addClass('active');
                }
                scrollTo(offset);

                return false;
            }
        });
    $('[data-role="toggleMenu"]')
        .on('click', function() {
            const
                $t = $(this),
                $h = $('#mainMenu');
            $t.toggleClass('active');
            $h.toggleClass('active');
        });
    $('[data-role="folder"]')
        .on('click', '[data-role="open"]', function() {
            const
                $t = $(this),
                $p = $t.parents('[data-role="folder"]').eq(0);

            $t.hide();
            $('[data-role="close"]', $p).show();
            $('[data-role="hidden"]', $p).slideDown(300);
            return false;
        })
        .on('click', '[data-role="close"]', function() {
            const
                $t = $(this),
                $p = $t.parents('[data-role="folder"]').eq(0);

            $t.hide();
            $('[data-role="open"]', $p).show();
            $('[data-role="hidden"]', $p).slideUp(300);
            return false;
        });
    $(window)
        .on('scroll', function() {
            const
                $t = $(this),
                st = $t.scrollTop() + 64,
                $btn = $('header .btn_small');
            if (st >= $t.height()) {
                $btn.fadeIn(300);
            } else {
                $btn.fadeOut(300);
            }
        });
    console.log('Document ready');
});
$(function() {
    function addPixel() {
        var partUrl = 'ads.travelaudience.com/js/ta.js';

        if ($('head').html().indexOf(partUrl) < 0) {
            var ttr = document.createElement('script');
            ttr.type = 'text/javascript';
            ttr.src = ('https:' == document.location.protocol ? 'https://' : 'http://')
                + partUrl;
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(ttr, s);
        }
    }

    $('body').on('click', '.js-pixel', function () {
        addPixel();
    });
});
